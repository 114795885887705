export const personaOptions = {
  hiring_manager: 'Hiring Manager',
  recruiter: 'Recruiter',
  recruiting_sourcer: 'Sourcer',
  recruiting_coord: 'Coordinator',
  recruiting_ops: 'Recruiting Operations',
  recruiting_emp_brand: 'Employer Brand',
  recruiting_leader: 'Recruiting Leadership',
  hr_bp: 'HR Business Partner',
  hr_leader: 'HR Leadership',
  hr_systems: 'HR and Recruiting Systems',
  deib: 'Diversity, Equity, Inclusion and Belonging',
  compensation: 'Compensation',
  other: 'Other',
};
