import {
  newReportsMiddleware,
  routingFromHiringReport,
  hiringReportMiddleware,
  executiveInsightsMiddleware,
} from '@/helpers/routeMiddleware';
import { handleRedirectToLogin } from '@/routes/helpers';

export const insights = (authDataStore) => ([
  {
    path: '/insights/',
    name: 'insights',
    component: () => import(/* webpackChunkName: "insights" */ '@/views/Insights.vue'),
    redirect: { name: 'recruiting-performance' },
    meta: { title: 'Insights' },
    children: [
      {
        name: 'executive-insights',
        path: 'executive-insights',
        component: () => import(/* webpackChunkName: "executive-insights" */ '@/components/insights/ExecutiveInsights.vue'),
        beforeEnter: executiveInsightsMiddleware(authDataStore),
        meta: { title: 'Executive Insights' },
      },
      {
        name: 'hiring-dynamics',
        path: 'hiring-dynamics',
        component: () => import(/* webpackChunkName: "hiring-dynamics" */ '@/components/insights/HiringDynamics.vue'),
        beforeEnter: newReportsMiddleware(authDataStore),
        meta: { title: 'Hiring Dynamics Report' },
      },
      {
        name: 'requisition-efficiency',
        path: 'requisition-efficiency',
        component: () => import(/* webpackChunkName: "requisition-efficiency" */ '@/components/insights/RequisitionEfficiency.vue'),
        beforeEnter: newReportsMiddleware(authDataStore),
        meta: { title: 'Requisition Efficiency Report' },
      },
      {
        name: 'candidate-pool-metrics',
        path: 'candidate-pool-metrics',
        component: () => import(/* webpackChunkName: "candidate-pool-metrics" */ '@/components/insights/CandidatePoolMetrics.vue'),
        beforeEnter: newReportsMiddleware(authDataStore),
        meta: { title: 'Candidate Pool Metrics' },
      },
      {
        name: 'overall-report',
        path: 'overall-report',
        component: () => import(/* webpackChunkName: "overall-report" */ '@/components/insights/OverallReport.vue'),
        beforeEnter: newReportsMiddleware(authDataStore),
        meta: { title: 'Overall Report' },
      },
      {
        name: 'inbound-report',
        path: 'inbound-report',
        component: () => import(/* webpackChunkName: "inbound-report" */ '@/components/insights/InboundReport.vue'),
        beforeEnter: newReportsMiddleware(authDataStore),
        meta: { title: 'Inbound Report' },
      },
      {
        name: 'outbound-report',
        path: 'outbound-report',
        component: () => import(/* webpackChunkName: "outbound-report" */ '@/components/insights/OutboundReport.vue'),
        beforeEnter: newReportsMiddleware(authDataStore),
        meta: { title: 'Outbound Report' },
      },
      {
        name: 'gender-report',
        path: 'gender-report',
        component: () => import(/* webpackChunkName: "gender-report" */ '@/components/insights/GenderReport.vue'),
        beforeEnter: newReportsMiddleware(authDataStore),
        meta: { title: 'Gender Report' },
      },
      {
        name: 'drop-off',
        path: 'drop-off',
        component: () => import(/* webpackChunkName: "drop-off" */ '@/components/insights/DropOff.vue'),
        beforeEnter: newReportsMiddleware(authDataStore),
        meta: { title: 'Drop-off Report' },
      },
      {
        name: 'pass-through',
        path: 'pass-through',
        component: () => import(/* webpackChunkName: "pass-through" */ '@/components/insights/Passthrough.vue'),
        beforeEnter: newReportsMiddleware(authDataStore),
        meta: { title: 'Pass-through Report' },
      },
      {
        name: 'compliance-report',
        path: 'compliance-report',
        component: () => import(/* webpackChunkName: "compliance-report" */ '@/components/insights/ComplianceReport.vue'),
        meta: { authStateRequired: 'either', title: 'Compliance Report' },
        beforeEnter: async (to, from, next) => {
          if (!window?.sessionStorage?.reportingShareKey && !to.query.key) {
            await handleRedirectToLogin(authDataStore, to, next);
            next();
          } else {
            next();
          }
        },
      },
      {
        name: 'easy-wins-report',
        path: 'easy-wins-report',
        component: () => import(/* webpackChunkName: "easy-wins-report" */ '@/components/insights/EasyWinsReport.vue'),
        meta: { authStateRequired: 'either', title: 'Easy Wins Report' },
        beforeEnter: async (to, from, next) => {
          if (!window?.sessionStorage?.reportingShareKey && !to.query.key) {
            await handleRedirectToLogin(authDataStore, to, next);
            next();
          } else {
            next();
          }
        },
      },
      {
        name: 'confidence-gap-report',
        path: 'confidence-gap-report',
        component: () => import(/* webpackChunkName: "confidence-gap-report" */ '@/components/insights/ConfidenceGapReport.vue'),
        meta: { authStateRequired: 'either', title: 'Confidence Gap Report' },
        beforeEnter: async (to, from, next) => {
          if (!window?.sessionStorage?.reportingShareKey && !to.query.key) {
            await handleRedirectToLogin(authDataStore, to, next);
            next();
          } else {
            next();
          }
        },
      },
      {
        name: 'hiring-report',
        path: 'hiring-report',
        component: () => import(/* webpackChunkName: "hiring-report" */ '@/components/insights/HiringReport.vue'),
        beforeEnter: hiringReportMiddleware(authDataStore),
        meta: { title: 'Hiring Report' },
      },
      {
        name: 'weekly-snapshot',
        path: 'weekly-snapshot',
        component: () => import(/* webpackChunkName: "weekly-snapshot" */ '@/components/insights/WeeklySnapshot/WeeklySnapshot.vue'),
        meta: { title: 'Weekly Snapshot Report' },
      },
      {
        name: 'recruiting-performance',
        path: 'recruiting-performance',
        component: () => import(/* webpackChunkName: "recruiting-performance" */ '@/components/insights/RecruitingPerformance/RecruitingPerformance.vue'),
        meta: { authStateRequired: 'either', title: 'Recruiting Performance Report' },
        beforeEnter: async (to, from, next) => {
          if (!window?.sessionStorage?.reportingShareKey && !to.query.key) {
            await handleRedirectToLogin(authDataStore, to, next);
            routingFromHiringReport({ to, from });
            next();
          } else {
            next();
          }
        },
      },
      {
        name: 'source-performance',
        path: 'source-performance',
        redirect: (to) => ({ name: 'overall-report', query: to.query }),
        meta: { title: 'Source Performance' },
      },
      {
        name: 'language-snapshot',
        path: 'language-snapshot',
        component: () => import(/* webpackChunkName: "language-snapshot" */ '@/components/insights/LanguageSnapshot/LanguageSnapshot.vue'),
        meta: { authStateRequired: 'either', title: 'Language Snapshot' },
        beforeEnter: async (to, from, next) => {
          if (!window?.sessionStorage?.reportingShareKey && !to.query.key) {
            await handleRedirectToLogin(authDataStore, to, next);
            next();
          } else {
            next();
          }
        },
      },
      {
        name: 'content-snapshot',
        path: 'content-snapshot',
        component: () => import(/* webpackChunkName: "content-snapshot" */ '@/components/insights/ContentSnapshot/ContentSnapshot.vue'),
        meta: { authStateRequired: 'either', title: 'Content Snapshot' },
        beforeEnter: async (to, from, next) => {
          if (!window?.sessionStorage?.reportingShareKey && !to.query.key) {
            await handleRedirectToLogin(authDataStore, to, next);
            next();
          } else {
            next();
          }
        },
      },
      {
        name: 'usage',
        path: 'usage',
        component: () => import(/* webpackChunkName: "usage" */ '@/components/insights/Usage/Usage.vue'),
        meta: { title: 'Usage Report' },
      },
    ],
  },
]);
