/* eslint-disable */
import { signupGetThirdPartyLibraryConfigs } from '@/api/signup';
import { storeToRefs } from 'pinia';
import { useAppThirdPartyPlugins } from '@/stores/app/thirdParty';
import { initBeamer } from './thirdParty/beamer';
import { initGoogleAnalytics } from './thirdParty/googleAnalytics';
import { initHubspot } from './thirdParty/hubspot';
import { initMixpanel } from './thirdParty/mixpanel';

export default {
  async install(app) {
    try {
      const responseJSON = await signupGetThirdPartyLibraryConfigs();
      if (responseJSON.success === true) {
        useAppThirdPartyPlugins().setThirdPartyConfigs(responseJSON);
        const {
          beamer, googleAnalytics, mixpanel, user,
        } = storeToRefs(useAppThirdPartyPlugins());

        if (beamer.value.enabled && !beamer.value.isGuest) {
          initBeamer({ beamer: beamer.value });
        }

        if (user.value && user.value.email) {
          initHubspot({ hubspot: user.value });
        }

        if (mixpanel.value.isEnabled) {
          initMixpanel({ mixpanel: mixpanel.value });
        }

        if (googleAnalytics.value.isEnabled) {
          initGoogleAnalytics();
        }
      }
    } catch (e) {
      console.log(e);
      console.log(e.message);
      console.error('tracking error');
    }
  },
};
