import * as Sentry from '@sentry/vue';
import { getEnvironment } from '@/helpers/environment';
import { useAppAuthData } from '@/stores/app/authData';
import { isNotTestAccount } from '@/helpers/thirdParty/verifyTestAccount';

const sentryEnvironment = getEnvironment();

/**
 * Generates regexp that matches https://*.{domain}/* URL
 * @param domain
 * @returns {RegExp}
 */
const anySubdomainRegexp = (domain) => new RegExp(
  `^https?:\\/\\/[^.]+\\.${domain.replace('.', '\\.')}`,
);

export const initSentry = ({ router, app }) => {
  if (process.env.VUE_APP_NO_SENTRY === 'true') {
    return;
  }

  const integrations = [
    Sentry.browserTracingIntegration({ router }),
  ];

  if (sentryEnvironment !== 'development') {
    integrations.push(
      Sentry.replayCanvasIntegration(),
      Sentry.replayIntegration({ maskAllText: false, blockAllMedia: false, maskAllInputs: false }),
    );
  }

  Sentry.init({
    app,
    dsn: 'https://d2648224f98144b4a64800ba904d7ef3@o1174908.ingest.sentry.io/6318863',
    integrations,
    logErrors: true,
    tracePropagationTargets: [
      anySubdomainRegexp('127.0.0.1.xip.io'),
      anySubdomainRegexp('datapeople.io'),
      anySubdomainRegexp('taprecruit.co'),
    ],
    tracesSampler: () => {
      const userId = useAppAuthData().authData?.userId;

      if (!window
        || !isNotTestAccount(userId)
      ) {
        return 0;
      }

      if (sentryEnvironment === 'development') return 0.1;

      return 0.5;
    },
    replaysSessionSampleRate: !window || window?.navigator?.userAgent?.endsWith('AWS-Canary')
      || window?.navigator?.userAgent?.endsWith('AWS-Canary-Noscript')
      ? 0
      : 1.0,
    environment: sentryEnvironment,
    // False to prevent it from tracking all the Vuetify component tree
    trackComponents: false,
    release: `vue-web-app@${process.env.VUE_APP_PACKAGE_VERSION}`,
  });
};
