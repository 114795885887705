import { defineStore } from 'pinia';
import { ref } from 'vue';
import { recruitingPerformance } from '@/api/insights';
import { useAppLoading } from '@/stores/app/loading';
import { useAppAlerts } from '@/stores/app/alerts';
import { useAppPagination } from '@/stores/app/pagination';
import { mergeFilters } from '@/stores/insights/helpers';
import { useFilters } from '@/stores/filters/filters';
import { useInsightsProject } from '@/stores/insights/insightsProject';

export const useRecruitingPerformance = defineStore('recruitingPerformance', () => {
  // State
  const periodData = ref([]);
  const documents = ref([]);
  const totals = ref({});
  const summary = ref({
    type: '',
    table: [],
  });

  // Actions
  const setDocuments = (newDocuments) => {
    documents.value = newDocuments;
  };

  const setPeriodData = (newPeriodData) => {
    periodData.value = newPeriodData;
  };

  const setTotals = (newTotals) => {
    totals.value = newTotals;
  };

  const setSummary = (newSummary) => {
    summary.value = newSummary;
  };

  const loadPage = async ({
    router,
    page: pageParam,
    routeQuery,
    selectedFilters,
    selectedMyJobs,
    selectedDateRange,
  }) => {
    try {
      const skeletonLoadersTimeout = setTimeout(() => {
        // add intermediate loading state to filter bar
        useAppLoading().setLoading({ halfTopBarLoading: false });
      }, 4000);

      useAppLoading().setLoading({
        pageLoading: true, filtersLoading: true, listLoading: true, halfTopBarLoading: true,
      });
      const { from, to } = selectedDateRange || {};
      const date = routeQuery?.from && routeQuery?.to
        ? { from: routeQuery.from, to: routeQuery.to }
        : { from, to };

      const parameters = {
        params: routeQuery,
        selectedMyJobs,
        selectedDateRange: date,
        selectedFilters,
        page: pageParam,
      };
      const {
        filters,
        dateRange,
        owned,
        periodData: respPeriodData,
        documents: respDocuments,
        pagination,
        totals: respTotals,
        summary: respSummary,
        projectImageUrl,
        projectName,
      } = await recruitingPerformance(parameters);

      clearTimeout(skeletonLoadersTimeout);

      if (dateRange.incomplete) {
        useAppAlerts().addAlert({
          message: 'Reporting may be incomplete for your account until enough data is available',
          thisPageOnly: true,
        });
      }

      useAppPagination().updatePagination(pagination);

      useFilters().updateFilters({ filters, myJobsEnabled: owned, dateRange });

      // Needs to happen right here but needs to wait for updateFilters commits to finish
      setTimeout(() => {
        const { page: respPage, totalPages } = pagination;
        const queryFilters = mergeFilters(filters);

        const query = {
          from: dateRange?.from || undefined,
          to: dateRange?.to || undefined,
          owned: owned?.value || undefined,
          page: totalPages > 1 ? respPage || 1 : undefined,
          ...queryFilters || {},
        };

        router.push({ query })
          .catch((err) => {
            if (err.message.startsWith('Avoided redundant navigation to current location')) {
              return;
            }
            throw err;
          });
      }, 0);

      setTotals(respTotals);
      setDocuments(respDocuments);
      setPeriodData(respPeriodData);
      setSummary(respSummary);
      useInsightsProject().setSharedProjectInfo({
        newProjectImageUrl: projectImageUrl,
        newProjectName: projectName,
      });
    } catch (e) {
      useAppAlerts().addAlert({ message: e.message });
      throw e;
    } finally {
      useAppLoading().setLoading({
        pageLoading: false, filtersLoading: false, listLoading: false, halfTopBarLoading: false,
      });
    }
  };

  return {
    // State
    periodData,
    documents,
    totals,
    summary,

    // Actions
    setDocuments,
    setPeriodData,
    setTotals,
    setSummary,
    loadPage,
  };
});
