import {
  mdiDotsVertical,
  mdiExclamation,
  mdiInformationOutline,
  mdiPlus,
  mdiChevronRight,
  mdiCheckBold,
  mdiMagnify,
  mdiMenuUp,
  mdiChevronLeft,
  mdiChevronDown,
  mdiMenuDown,
  mdiContentCopy,
  mdiMenuRight,
  mdiChevronUp,
  mdiAlertCircle,
  mdiCloseCircleOutline,
  mdiArrowRight,
  mdiClose,
  mdiRhombus,
  mdiCheckboxMarkedCircle,
  mdiPoll,
  mdiCircleSmall,
  mdiCloseCircle,
  mdiCheck,
  mdiPower,
} from '@mdi/js';

export default {
  'dots-vertical': mdiDotsVertical,
  'information-outline': mdiInformationOutline,
  plus: mdiPlus,
  exclamation: mdiExclamation,
  'chevron-right': mdiChevronRight,
  'chevron-left': mdiChevronLeft,
  'check-bold': mdiCheckBold,
  magnify: mdiMagnify,
  'menu-up': mdiMenuUp,
  'menu-down': mdiMenuDown,
  'content-copy': mdiContentCopy,
  'menu-right': mdiMenuRight,
  'chevron-down': mdiChevronDown,
  'chevron-up': mdiChevronUp,
  'alert-circle': mdiAlertCircle,
  'close-circle-outline': mdiCloseCircleOutline,
  'arrow-right': mdiArrowRight,
  close: mdiClose,
  rhombus: mdiRhombus,
  'checkbox-marked-circle': mdiCheckboxMarkedCircle,
  poll: mdiPoll,
  'circle-small': mdiCircleSmall,
  'close-circle': mdiCloseCircle,
  check: mdiCheck,
  power: mdiPower,
};
