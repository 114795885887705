<template>
  <button v-if="show && route.name" class="sentry-feedback-button">
    Report a Bug
  </button>
</template>

<script setup>
import {
  computed, watch, ref, nextTick,
} from 'vue';
import { getEnvironment } from '@/helpers/environment';
import { getReadableTimezone } from '@/helpers/timeZone';
import { useAppAuthData } from '@/stores/app/authData';
import { storeToRefs } from 'pinia';
import { feedbackIntegration } from '@sentry/vue';
import { startCase } from 'lodash-es';
import { useRoute } from 'vue-router';

const route = useRoute();

const isSentryAttachedToButton = ref(false);

const show = computed(() => {
  const environment = getEnvironment();

  if (environment !== 'development') {
    return true;
  }

  return false;
});

watch(route, () => {
  nextTick(() => {
    if (show.value && !isSentryAttachedToButton.value) {
      const { authData } = storeToRefs(useAppAuthData());
      const timezone = getReadableTimezone();
      const isDatapeopleAnywhere = route.query.view === 'chrome';
      const {
        projectName, integrationType, atsId, role,
      } = authData.value;

      feedbackIntegration({
        autoInject: false,
        showBranding: false,
        enableScreenshot: false,
        colorScheme: 'light',
        tags: {
          projectName,
          integration: integrationType,
          atsId,
          role: startCase(role),
          timezone,
          isDatapeopleAnywhere,
        },
      }).attachTo(
        document.querySelector('.sentry-feedback-button'),
      );

      isSentryAttachedToButton.value = true;
    }
  });
});
</script>

<style lang="less" scoped>
.sentry-feedback-button {
  position: fixed;
  transform: rotate(-90deg);
  top: calc(50% - 47px);
  right: -43px;
  padding: 8px 12px 10px 12px;
  letter-spacing: 0.2px;
  font-size: 16px;
  line-height: normal;
  background-color: @primary;
  color: @pure-white;
  font-weight: 400;
  border-radius: 6px 6px 0 0;
  cursor: pointer;
  transition: all 0.2s;
  opacity: 0.8;

  &:hover {
    right: -41px;
    box-shadow: -0px 0 30px 2px @secondary-lighten-80;
    opacity: 1;
  }
}
</style>

<style lang="less">
#sentry-feedback {
  --font-family: 'Roboto', sans-serif;
  --foreground: @secondary;
  --accent-background: @primary;
  --success-color: @caribbean-green;
  --error-color: @valencia-red;
  --box-shadow: 0 5px 20px fade(@secondary, 10%);
}
</style>
