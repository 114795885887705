import { defineStore } from 'pinia';
import { isEmpty, isEqual } from 'lodash-es';
import {
  complianceReport,
  sendActionableInsightsShareLinks,
  complianceReportDownload,
  easyWinsReportDownload,
  confidenceGapReportDownload,
  easyWinsReport,
  confidenceGapReport,
} from '@/api/insights';
import { removeEmptyParams } from '@/stores/filters/helpers';
import { useAppAlerts } from '@/stores/app/alerts';
import { useAppPagination } from '@/stores/app/pagination';
import { useAppRoute } from '@/stores/app/route';
import { useAppLoading } from '@/stores/app/loading';
import { useModals } from '@/stores/modals/modals';
import { ref } from 'vue';
import { useActionableInsightsForm } from '@/stores/insights/actionableInsightsForm';
import { mergeFilters } from '@/stores/insights/helpers';
import { useInsightsProject } from '@/stores/insights/insightsProject';
import { useFilters } from '../filters/filters';

export const useActionableInsights = defineStore('actionableInsights', () => {
  // Other stores
  const { addAlert } = useAppAlerts();
  const { updatePagination } = useAppPagination();
  const routeStore = useAppRoute();
  const form = useActionableInsightsForm();
  const { setLoading } = useAppLoading();
  const { setModal } = useModals();

  // State
  const selectedReportJobs = ref([]);
  const documents = ref([]);
  const totals = ref({});
  const exclusions = ref({});

  // Actions
  const setSelectedReportJobs = (jobs = []) => {
    selectedReportJobs.value = jobs;
  };

  const setActionableInsightsData = (actionableInsight) => {
    documents.value = actionableInsight?.documents || [];
    totals.value = actionableInsight?.totals || {};
    exclusions.value = actionableInsight?.exclusions || {};
  };

  const getComplianceReport = async (
    {
      routeQuery,
      selectedFilters,
      page,
      sort,
      router,
      selectedMyJobs,
      resetData,
    },
  ) => {
    if (resetData) setSelectedReportJobs([]);

    const params = routeQuery || {};

    params.page = page || undefined;

    const {
      filters,
      pagination,
      owned,
      totals: respTotals,
      documents: respDocuments,
      sorting,
      exclusions: respExclusions,
      projectImageUrl,
      projectName,
    } = await complianceReport({
      params,
      selectedMyJobs,
      selectedFilters,
      sort,
    });

    const subMenuOptions = sorting;

    if (!isEmpty(sorting) && !respDocuments?.length) {
      subMenuOptions.disabled = true;
    }

    /* Send relevant updates to filters */
    useFilters().updateFilters({
      filters,
      myJobsEnabled: owned,
      subMenuOptions,
    });

    setActionableInsightsData({
      documents: respDocuments,
      totals: respTotals,
      exclusions: respExclusions,
    });

    updatePagination(pagination);

    useInsightsProject().setSharedProjectInfo({
      newProjectImageUrl: projectImageUrl,
      newProjectName: projectName,
    });

    const prunedRootStateQuery = removeEmptyParams(routeStore.route?.query);
    const prunedRouteQuery = removeEmptyParams(routeQuery);

    /* update URL with shareable filter link using router.replace */
    if (
      router
      && (!isEqual(prunedRootStateQuery, prunedRouteQuery))
    ) {
      const queryFilters = mergeFilters(filters);

      const newQuery = {
        ...params,
        ...queryFilters,
        from: undefined,
        to: undefined,
        legacy: undefined,
        sort: sorting.value,
      };

      if (selectedMyJobs) {
        newQuery.owned = selectedMyJobs;
      }

      router.push({
        query: newQuery,
      }).catch(() => true); // ignore duplicate navigation error
    }
  };

  const getEasyWinsReport = async (
    {
      routeQuery,
      selectedFilters,
      page,
      sort,
      router,
      selectedMyJobs,
      resetData,
    },
  ) => {
    if (resetData) setSelectedReportJobs([]);

    const params = routeQuery || {};

    params.page = page || undefined;

    const {
      filters,
      pagination,
      owned,
      totals: respTotals,
      documents: respDocuments,
      sorting,
      exclusions: respExclusions,
      projectImageUrl,
      projectName,
    } = await easyWinsReport({
      params,
      selectedMyJobs,
      selectedFilters,
      sort,
    });

    const subMenuOptions = sorting;

    if (!isEmpty(sorting) && !respDocuments?.length) {
      subMenuOptions.disabled = true;
    }

    /* Send relevant updates to filters */
    useFilters().updateFilters({
      filters,
      myJobsEnabled: owned,
      subMenuOptions,
    });

    setActionableInsightsData({
      documents: respDocuments,
      totals: respTotals,
      exclusions: respExclusions,
    });

    updatePagination(pagination);

    useInsightsProject().setSharedProjectInfo({
      newProjectImageUrl: projectImageUrl,
      newProjectName: projectName,
    });

    const prunedRootStateQuery = removeEmptyParams(routeStore.route?.query);
    const prunedRouteQuery = removeEmptyParams(routeQuery);

    /* update URL with shareable filter link using router.replace */
    if (
      router
      && (!isEqual(prunedRootStateQuery, prunedRouteQuery))
    ) {
      const queryFilters = mergeFilters(filters);

      const newQuery = {
        ...params,
        ...queryFilters,
        from: undefined,
        to: undefined,
        sort: sorting.value,
        legacy: undefined,
      };

      if (selectedMyJobs) {
        newQuery.owned = selectedMyJobs;
      }

      router.push({
        query: newQuery,
      }).catch(() => true); // ignore duplicate navigation error
    }
  };

  const getConfidenceGapReport = async (
    {
      routeQuery,
      selectedFilters,
      page,
      sort,
      router,
      selectedMyJobs,
      resetData,
    },
  ) => {
    if (resetData) setSelectedReportJobs([]);

    const params = routeQuery || {};

    params.page = page || undefined;

    const {
      filters,
      pagination,
      owned,
      totals: respTotals,
      documents: respDocuments,
      sorting,
      projectImageUrl,
      projectName,
    } = await confidenceGapReport({
      params,
      selectedMyJobs,
      selectedFilters,
      sort,
    });

    const subMenuOptions = sorting;

    if (!isEmpty(sorting) && !respDocuments?.length) {
      subMenuOptions.disabled = true;
    }

    /* Send relevant updates to filters */
    useFilters().updateFilters({
      filters,
      myJobsEnabled: owned,
      subMenuOptions,
    });

    setActionableInsightsData({ documents: respDocuments, totals: respTotals });
    updatePagination(pagination);
    useInsightsProject().setSharedProjectInfo({
      newProjectImageUrl: projectImageUrl,
      newProjectName: projectName,
    });

    const prunedRootStateQuery = removeEmptyParams(routeStore.route?.query);
    const prunedRouteQuery = removeEmptyParams(routeQuery);

    /* update URL with shareable filter link using router.replace */
    if (
      router
      && (!isEqual(prunedRootStateQuery, prunedRouteQuery))
    ) {
      const queryFilters = mergeFilters(filters);

      const newQuery = {
        ...params,
        ...queryFilters,
        from: undefined,
        to: undefined,
        sort: sorting.value,
        legacy: undefined,
      };

      if (selectedMyJobs) {
        newQuery.owned = selectedMyJobs;
      }

      router.push({
        query: newQuery,
      }).catch(() => true); // ignore duplicate navigation error
    }
  };

  const downloadComplianceReport = async ({
    filters, dateRange, myJobsEnabled, document, sort,
  }) => {
    try {
      complianceReportDownload({
        document, filters, dateRange, myJobsEnabled, sort,
      });
    } catch (error) {
      addAlert({ message: `Compliance Report Download Error: ${error.message}` });
      throw error;
    }
  };

  const downloadEasyWinsReport = async ({
    filters, dateRange, myJobsEnabled, document, sort,
  }) => {
    try {
      easyWinsReportDownload({
        document, filters, dateRange, myJobsEnabled, sort,
      });
    } catch (error) {
      addAlert({ message: `Easy Wins Report Download Error: ${error.message}` });
      throw error;
    }
  };

  const downloadConfidenceGapReport = async ({
    filters, dateRange, myJobsEnabled, document, sort,
  }) => {
    try {
      confidenceGapReportDownload({
        document, filters, dateRange, myJobsEnabled, sort,
      });
    } catch (error) {
      addAlert({ message: `Confidence Gap Report Download Error: ${error.message}` });
      throw error;
    }
  };

  const submitActionableInsightsShareForm = async ({ updateEmailsAction, reportUrl }) => {
    try {
      setLoading({ modalLoading: true });
      const { userIds = [], note = '' } = form.values;
      const jobIds = (selectedReportJobs.value || []).map(({ id }) => (typeof id === 'number' ? id : parseInt(id, 10)));

      const {
        success,
        failed,
        succeeded,
        errors,
        code,
      } = await sendActionableInsightsShareLinks({
        userIds, note, jobIds, reportUrl,
      });

      if (success) {
        addAlert({
          message: 'Email has been sent',
          type: 'success',
          thisPageOnly: true,
        });
        setModal({ field: 'actionableInsight', value: false });
        form.resetForm();
        updateEmailsAction({ succeeded: [], failed: [] });
      }

      if (!success && succeeded && failed) {
        updateEmailsAction({ succeeded, failed });
      }

      if (code && code === 'validation_failed') {
        form.setBackendErrors(errors);
      }
    } catch (error) {
      addAlert({
        message: `Share failed - ${error.message}`,
        thisPageOnly: true,
      });
      throw error;
    } finally {
      setLoading({ modalLoading: false });
    }
  };

  return {
    selectedReportJobs,
    documents,
    totals,
    exclusions,
    setSelectedReportJobs,
    setActionableInsightsData,
    getComplianceReport,
    getEasyWinsReport,
    getConfidenceGapReport,
    submitActionableInsightsShareForm,
    downloadComplianceReport,
    downloadEasyWinsReport,
    downloadConfidenceGapReport,
  };
});
